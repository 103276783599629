import request from '@/utils/request'


// 查询后巷屋列表
export function listHouses(query) {
  return request({
    url: '/laneway/lanewayHouses/list',
    method: 'get',
    params: query
  })
}

// 查询后巷屋分页
export function pageHouses(query) {
  return request({
    url: '/laneway/lanewayHouses/page',
    method: 'get',
    params: query
  })
}

// 查询后巷屋详细
export function getHouses(data) {
  return request({
    url: '/laneway/lanewayHouses/detail',
    method: 'get',
    params: data
  })
}

// 新增后巷屋
export function addHouses(data) {
  return request({
    url: '/laneway/lanewayHouses/add',
    method: 'post',
    data: data
  })
}

// 修改后巷屋
export function updateHouses(data) {
  return request({
    url: '/laneway/lanewayHouses/edit',
    method: 'post',
    data: data
  })
}

// 删除后巷屋
export function delHouses(data) {
  return request({
    url: '/laneway/lanewayHouses/delete',
    method: 'post',
    data: data
  })
}
