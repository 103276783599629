<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
       <b>{{ formTitle == '添加' ? $t('通用.新增') : $t('通用.修改') }} </b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('服务管理.后巷屋管理.封面')" prop="cover">
        <file-upload :defaultList="coverList" type="image" @input="imageChange"></file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.视频')" prop="videoUrl">
        <file-upload type="file" :defaultList="videoList" @input="videoChange($event, 'picture')"></file-upload>
        <video
          v-if="form.videoUrl"
          style="width: 80px; height: 80px"
          class="avatar video-avatar"
          controls="controls"
          :src="form.videoUrl"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.图片上传')">
        <file-upload :defaultList="filelist" @input="handleChange" :count="10">
          <!-- <div v-if="filelist.length < 8">
            <a-icon type="plus" />
            <div class="ant-upload-text">多图上传</div>
          </div> -->
        </file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.定金')" prop="deposit">
        <a-input v-model="form.deposit" :placeholder="$t('服务管理.后巷屋管理.请输入定金')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.预估价格')" prop="estimatedPrice">
        <a-input v-model="form.estimatedPrice" :placeholder="$t('服务管理.后巷屋管理.请输入预估价格')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.标题')" prop="title">
        <a-input v-model="form.title" :placeholder="$t('服务管理.后巷屋管理.请输入标题')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.内容')" prop="content">
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.后巷屋管理.备注')" prop="remark">
        <a-input v-model="form.remark" :placeholder="$t('服务管理.后巷屋管理.请输入备注')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> {{$t('通用.保存')}} </a-button>
          <a-button type="dashed" @click="cancel"> {{$t('通用.取消')}} </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getHouses, addHouses, updateHouses } from '@/api/laneway/houses'
import Editor from '@/components/Editor'
import { guid } from '@/utils/ruoyi'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Editor,
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      previewVisible: false,
      previewImage: '',
      filelist: [],
      videoList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        cover: null,

        videoUrl: null,

        picUrl: null,

        deposit: null,

        estimatedPrice: null,

        title: null,

        content: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cover: [{ required: true, message: '封面不能为空', trigger: 'blur' }],
        videoUrl: [{ required: true, message: '视频地址不能为空', trigger: 'blur' }],
        picUrl: [{ required: true, message: '图片地址,多个使用逗号隔开不能为空', trigger: 'blur' }],
        deposit: [{ required: true, message: '定金不能为空', trigger: 'blur' }],
        estimatedPrice: [{ required: true, message: '预估价格不能为空', trigger: 'blur' }],
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
      },
      coverList: [],
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.$nextTick(() => {
        this.form = {
          id: null,
          cover: null,
          videoUrl: null,
          picUrl: null,
          deposit: null,
          estimatedPrice: null,
          title: null,
          content: null,
          createTime: null,
          remark: null,
        }
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
      this.coverList = []
      this.videoList = []
      this.filelist = []
      this.form.videoUrl = ''
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      // console.log('row', row)
      let that = this
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHouses({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.form.cover = row.cover
      this.form.videoUrl = row.videoUrl
      this.coverList = []
      this.filelist = []
      this.coverList.push({
        uid: guid(8, 10),
        name: row.cover,
        status: 'done',
        halfUrl: row.cover,
        url: row.cover,
        path: row.cover,
      })
      row.picUrl.split(',').map((e) => {
        // 将返回的多个图片地址切割成数组进行循环
        this.filelist.push({
          uid: guid(8, 10),
          name: e,
          status: 'done',
          halfUrl: e,
          url: e,
          path: e,
        })
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHouses(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.coverList = []
                this.videoList = []
                this.filelist = []
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addHouses(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
                this.coverList = []
                this.videoList = []
                this.filelist = []
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    //图片上传
    imageChange(e) {
      //  console.log('eeee',e)
      this.form.cover = e[0].path
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange(fileList) {
      console.log('多个图片', fileList)
      const picurl = []
      fileList.map((item) => {
        picurl.push(item.url)
      })
      this.form.picUrl = picurl.join(',')
     
    },
    handleCancel() {
      this.previewVisible = false
    },
    videoChange(info, fieldName) {
      console.log('video', info)
      if (info.length == 0) {
        this.form.videoUrl = ''
        this.videoList = []
      }
      this.form.videoUrl = info[0].url
    },
  },
}
</script>
